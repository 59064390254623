import { createContext, useContext, useEffect, useMemo, useState } from "react";
import type { PropsWithChildren } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal } from "antd";
import { R } from "common/request";
import { useRequest } from "ahooks";
import { parseCookies } from "nookies";
import { IUserInfo } from "common/interface/login";
import { menuData } from "@/components/menu/const";
import { getEnabledCourseTypes } from "../utils";
import { PACKAGE } from "../const";

// 全局信息
export const GlobalContext = createContext({
  userInfo: {} as IUserInfo,
  trialInfo: {} as any,
  roleIds: [] as number[],
  isStu: false,
  deleteConfirm: (title?: string, content?: string) => new Promise((resolve, reject) => {}),
  setUser: (params: IUserInfo) => {},
  commonInfo: {} as any,
  quotaNotice: false,
  setQuotaNotice: (params: any) => {},
  posts: [],
  menuList: [] as any[],
  statsInfo: {} as any,
  setStatsInfo: (params: any) => {},
  packageInfo: {} as any
});

export interface GlobalProps {
  userInfo: IUserInfo;
}

// 明确定义权限映射类型
type PermissionMapType = {
  default: number;
  6: number;
  7: number;
};

// 权限映射配置
const permissionMap: PermissionMapType = {
  default: 1, // id < 6 的默认权限
  6: 3, // id = 6 的权限
  7: 2 // id = 7 的权限
};

const getPermission = (id: number) => {
  const itemId = Number(id);
  const key = itemId === 6 || itemId === 7 ? itemId : "default";
  return permissionMap[key];
};

export const GlobalProvider = ({ children, userInfo }: PropsWithChildren<GlobalProps>) => {
  const [modal, contextHolder] = Modal.useModal();
  const [loginInfo, setLoginInfo] = useState();
  const [userInfoState, setUserInfoState] = useState(userInfo);
  const [commonInfo, setCommonInfo] = useState<any>({});
  const { token } = parseCookies(null, "token");
  const [quotaNotice, setQuotaNotice] = useState<any>(false);
  const [showCoachTab, setShowCoachTab] = useState<boolean>(true);
  const [statsInfo, setStatsInfo] = useState<any>({});

  useRequest(() => R("common/settings"), {
    onSuccess: res => {
      const enabled_course_types = getEnabledCourseTypes(res?.data?.company_functions);
      setCommonInfo({
        ...res.data,
        enabled_course_types,
        asset_types: res?.data?.asset_types?.filter((item: { id: number }) =>
          (res.data?.company_functions?.knowledge_base ?? [])?.includes(getPermission(item?.id))
        ),
        course_learn_pro: res.data?.company_functions?.course_type_3 === 3
      });
      localStorage.setItem(
        "is_company_customized",
        JSON.stringify({ show: res?.data?.company_functions?.is_company_customized })
      );
    },
    ready: Boolean(token)
  });

  useRequest(() => R("company/stats"), {
    onSuccess: res => {
      setQuotaNotice(!!res?.data?.global_tips);
      setStatsInfo(res.data);
    },
    ready: Boolean(token)
  });

  useEffect(() => {
    let cacheInfo: any = localStorage.getItem("loginInfo");
    if (cacheInfo && JSON.parse(cacheInfo)) {
      cacheInfo = JSON.parse(JSON.parse(cacheInfo));
      setLoginInfo(cacheInfo as any);
    }
    const no_tutor = localStorage.getItem("showCoachTab");
    if (no_tutor && JSON.parse(no_tutor)) {
      const showTab = JSON.parse(JSON.parse(no_tutor));
      const showTabShow = showTab?.show ?? true;
      setShowCoachTab(cacheInfo?.role?.id > 3 ? showTabShow : true);
    } else {
      setShowCoachTab(true);
    }
  }, [children]);

  const newUserInfo = loginInfo || userInfo;
  const isStu = newUserInfo?.role?.id === 4;

  const { data: userRes } = useRequest(() => R("users/check_study_time"), {
    ready: Boolean(token) && newUserInfo?.is_trial_user,
    pollingInterval: 1500,
    pollingWhenHidden: true,
    pollingErrorRetryCount: 3
  });

  const trialInfo = useMemo(() => {
    const { study_time, is_trial_user } = userRes?.data || (newUserInfo as any) || {};
    // const study_time = newUserInfo?.study_time || 0;
    const isTrial = Boolean(is_trial_user);
    const remainingTime = study_time;
    const isExpired = study_time < 1;
    return { isTrial, isExpired: isTrial ? isExpired : false, remainingTime };
  }, [newUserInfo, userRes?.data]);

  const posts = useMemo(
    () =>
      commonInfo?.industry?.find((item: any) => item.id === userInfoState?.company?.industry)
        ?.posts || [],
    [userInfoState, commonInfo]
  );

  const packageInfo = useMemo(() => {
    const _package = newUserInfo?.company?.package_name;
    if (newUserInfo?.is_trial_user) {
      return { id: PACKAGE.Trial, name: "体验版" };
    }
    if (!_package) {
      return { id: PACKAGE.Customized, name: "定制版" };
    }
    return { id: _package, name: _package };
  }, [newUserInfo]);

  const menuList = useMemo(() => {
    const enabled_course_types = getEnabledCourseTypes(newUserInfo?.settings?.company_functions);
    const _newUserInfo = {
      ...newUserInfo,
      enabled_course_types: commonInfo?.enabled_course_types ?? enabled_course_types
    };
    const enabled_material = commonInfo?.company_functions?.knowledge_base?.length > 0;
    const list =
      menuData.filter(item => {
        if (_newUserInfo?.is_trial_user) {
          // 体验版显示学员报告 其他都显示
          return item.key !== "/report";
        }
        if (packageInfo?.id === PACKAGE.Personal) {
          // 个人版权限是管理员，但显示学员报告
          if (item.key === "/report") {
            return false;
          }
          if (item.key === "/record") {
            return true;
          }
        }
        if (item.key === "/coach") {
          // 无实战课程不显示陪练
          return showCoachTab && !!item.filter?.(_newUserInfo);
        }
        if (item.key === "/material") {
          return enabled_material && !!item.filter?.(_newUserInfo);
        }
        return !!item.filter?.(_newUserInfo);
      }) || [];

    return list.map((i: any) => {
      let locked = false;
      if (trialInfo?.isTrial) {
        if (["/material", "/setting"].includes(i.key)) {
          locked = true;
        }
      }
      return { ...i, locked };
    });
  }, [commonInfo, newUserInfo, showCoachTab, trialInfo?.isTrial, packageInfo]);

  useEffect(() => {
    setUserInfoState(newUserInfo);
  }, [newUserInfo]);

  const deleteConfirm = async (title = "提示", content = "删除后不可恢复，请确认删除？"): Promise<boolean> =>
    new Promise((resolve, reject) => {
      modal.confirm({
        title,
        content,
        icon: <ExclamationCircleFilled />,
        keyboard: false,
        cancelText: "取消",
        onOk: () => resolve(true),
        onCancel: () => reject()
      });
    });

  return (
    <GlobalContext.Provider
      value={{
        userInfo: userInfoState,
        trialInfo,
        roleIds: [userInfo?.role?.id],
        isStu,
        deleteConfirm,
        setUser: info => setUserInfoState(info),
        commonInfo,
        quotaNotice,
        setQuotaNotice,
        posts,
        menuList,
        statsInfo,
        setStatsInfo,
        packageInfo
      }}
    >
      {contextHolder}
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => useContext(GlobalContext);
